import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons';

import routes from '~/configs/routes';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestAuthCreatePackage, requestAuthGetPackagesByGameID } from '~/services/game';

function CreatePackage() {
    const [game, setGame] = useState(null);
    const [amounts, setAmounts] = useState([]);

    const [tag, setTag] = useState('');
    const [title, setTitle] = useState('');
    const [amount, setAmount] = useState('');
    const [priority, setPriority] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [description, setDescription] = useState('');

    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Thêm mới gói quà - Quản trị website';

        if (id) {
            const fetch = async () => {
                dispatch(startLoading());
                const result = await requestAuthGetPackagesByGameID(id, 'package');

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(routes.login);
                } else if (result.status === 200) {
                    setGame(result.game);
                    setAmounts(result.data);
                } else {
                    navigate(routes.games);
                    alertError(result.error);
                }
            };
            fetch();
        } else {
            navigate(routes.games);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleUpdateTelco = async () => {
        if (!game._id) {
            return alertError('ID trò chơi không tồn tại');
        }
        if (!amount) {
            return alertError('ID mệnh giá không được để trống');
        }
        if (!title) {
            return alertError('Tên gói quà không được để trống');
        }
        if (!imageUrl) {
            return alertError('Link ảnh không được để trống');
        }
        if (!priority) {
            return alertError('Sắp xếp trò chơi không được để trống');
        }

        const data = {
            game_id: game._id,
            amount_id: amount,
            title,
            image_url: imageUrl,
            priority,
            tag,
            description,
        };

        dispatch(startLoading());
        const result = await requestAuthCreatePackage(data);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            setTag('');
            setTitle('');
            setAmount('');
            setImageUrl('');
            setPriority('');
            setDescription('');

            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Thêm mới gói quà" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Thêm mới gói quà</Card.Title>

                                <Link to={routes.games + routes.package + '/' + id}>
                                    <Button variant="warning">
                                        <FontAwesomeIcon icon={faHouseChimney} />
                                        <span>Trang chính</span>
                                    </Button>
                                </Link>
                            </Card.Header>

                            <Card.Body>
                                <Col xl={6} className="p-0 mt-3">
                                    <Row>
                                        <div className="form-group col-md-12 mb-4">
                                            <label>Trò chơi:</label>
                                            <input type="text" className="form-control" defaultValue={game?.title} readOnly />
                                        </div>

                                        <div className="form-group col-md-12 mb-4">
                                            <label>Mệnh giá:</label>
                                            <select className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)}>
                                                <option value="">Chọn mệnh giá</option>
                                                {amounts.map((amount) => (
                                                    <option value={amount._id} key={amount._id}>
                                                        {amount.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group col-md-12 mb-4">
                                            <label className="d-block">Tên gói quà:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Tên gói quà"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-12 mb-4">
                                            <label className="d-block">Link ảnh:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Link ảnh"
                                                value={imageUrl}
                                                onChange={(e) => setImageUrl(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-12 mb-4">
                                            <label className="d-block">Sắp xếp:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Sắp xếp"
                                                value={priority}
                                                onChange={(e) => setPriority(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-12 mb-4">
                                            <label className="d-block">Tag:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Tag"
                                                value={tag}
                                                onChange={(e) => setTag(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-12">
                                            <label>Mô tả:</label>
                                            <textarea
                                                className="form-control"
                                                rows={3}
                                                placeholder="Có thể bỏ qua nếu không có"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={handleUpdateTelco}>Thêm mới</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default CreatePackage;
