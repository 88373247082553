import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Card, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import routes from '~/configs/routes';
import PageTitle from '~/components/PageTitle';
import ModalTelco from '~/pages/softcard/ModalTelco';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestAuthGetTelcos, requestAuthUpdateTelco } from '~/services/telco';

function Telco() {
    const [telco, setTelco] = useState(null);
    const [telcos, setTelcos] = useState([]);
    const [openUpdate, setOpenUpdate] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách thẻ cào - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestAuthGetTelcos();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                setTelcos(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Hàm thay đổi trạng thái
    const handleToggleTelco = async (id, type) => {
        if (!id) {
            alertError('ID thẻ không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestAuthUpdateTelco(id, type, {});

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                const cloneTelcos = [...telcos];

                const indexTelco = cloneTelcos.findIndex((telco) => telco._id === id);
                if (indexTelco === -1) {
                    return alertError('Thẻ không tồn tại trong danh sách');
                }

                if (type === 'status') {
                    cloneTelcos[indexTelco].status = result.data;
                }
                if (type === 'active') {
                    cloneTelcos[indexTelco].active = result.data;
                }
                setTelcos(cloneTelcos);
                alertSuccess(result.message);
            } else {
                alertError(result.error);
            }
        }
    };

    const activeTelcos = telcos.filter((telco) => telco.active);
    const activatedTelcos = telcos.filter((telco) => !telco.active);

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Danh sách thẻ" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Thẻ đã cài đặt</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Tên</th>
                                                <th>Mã</th>
                                                <th>Ảnh</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activeTelcos.length > 0 ? (
                                                activeTelcos.map((telco) => (
                                                    <tr key={telco._id}>
                                                        <td>{telco.priority}</td>
                                                        <td>{telco.title}</td>
                                                        <td>{telco.code}</td>
                                                        <td>
                                                            <img
                                                                src={telco.image_url}
                                                                alt={telco.telco}
                                                                style={{ width: 120, height: 70 }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${telco.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggleTelco(telco._id, 'status')}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span>{moment(telco.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                className="mr-2"
                                                                variant="info"
                                                                title="Sửa"
                                                                onClick={() => {
                                                                    setTelco(telco);
                                                                    setOpenUpdate(true);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faPen} />
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Gỡ cài đặt"
                                                                onClick={() => handleToggleTelco(telco._id, 'active')}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="mt-5">
                            <Card.Header>
                                <Card.Title>Thẻ chưa cài đặt</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Tên</th>
                                                <th>Mã</th>
                                                <th>Ảnh</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activatedTelcos.length > 0 ? (
                                                activatedTelcos.map((telco) => (
                                                    <tr key={telco._id}>
                                                        <td>{telco.priority}</td>
                                                        <td>{telco.title}</td>
                                                        <td>{telco.code}</td>
                                                        <td>
                                                            <img
                                                                src={telco.image_url}
                                                                alt={telco.telco}
                                                                style={{ width: 120, height: 70 }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <span>{moment(telco.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="success"
                                                                onClick={() => handleToggleTelco(telco._id, 'active')}
                                                            >
                                                                Cài đặt
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {openUpdate && telco && (
                <ModalTelco show={openUpdate} setShow={setOpenUpdate} telco={telco} callback={telcos} setCallback={setTelcos} />
            )}
        </div>
    );
}

export default Telco;
