import { Tooltip } from 'react-tippy';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faRightToBracket } from '@fortawesome/free-solid-svg-icons';

import './Header.css';
import config from '~/configs';
import { requestLogoutUser } from '~/services/auth';
import { logoutSuccess } from '~/redux/reducer/auth';
import { closeSidebar, openSidebar, startLoading, stopLoading } from '~/redux/reducer/module';

const { home, statistic, chargings, login } = config.routes;

function getDomainWithProtocol(url) {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;
    const protocol = parsedUrl.protocol;

    // Phân tích hostname để lấy phần tên miền chính
    const parts = hostname.split('.');
    // Chỉ lấy 2 phần cuối của hostname để bỏ qua các subdomain
    const mainDomain = parts.length > 2 ? parts.slice(-2).join('.') : hostname;

    // Trả về tên miền kèm theo protocol
    return `${protocol}//${mainDomain}`;
}

function Header() {
    const { sidebar } = useSelector((state) => state.module);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { origin } = window.location;

    const hostname = getDomainWithProtocol(origin);

    const handleToggleSidebar = () => {
        if (sidebar) {
            dispatch(closeSidebar());
            document.body.classList.remove('open');
        } else {
            dispatch(openSidebar());
            document.body.classList.add('open');
        }
    };

    const handleLogout = async () => {
        dispatch(startLoading());
        await requestLogoutUser();
        dispatch(stopLoading());
        dispatch(logoutSuccess());
        navigate(login);
        window.location.reload();
    };

    return (
        <div className="wrapper-header">
            <nav className="header-nav">
                <div className="header-left">
                    <div className="header-icon" onClick={handleToggleSidebar}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                    <div className="header-title">
                        <Link to={home}>Trang chủ</Link>
                    </div>
                    <div className="header-title">
                        <Link to={statistic + chargings}>Thanh toán</Link>
                    </div>
                    <div className="header-title">
                        <a href={hostname + '/billing'} target="_blank" rel="noreferrer">
                            Fake bill
                        </a>
                    </div>
                </div>
                <div className="header-right">
                    <Tooltip
                        position="bottom"
                        trigger="click"
                        interactive
                        offset={-190}
                        html={
                            <div className="tippy">
                                <header>
                                    <h6>Thông báo</h6>
                                </header>
                                <div className="tippy-content">
                                    <div className={`tippy-item tippy-watch`}>
                                        <div className="tippy-avatar">
                                            <img src="https://i.imgur.com/GnKuo0m.jpeg" alt="Avatar" />
                                        </div>
                                        <div className="tippy-message">Thông báo thử nghiệm</div>
                                    </div>
                                </div>
                                <a href="/notifications" target="_blank" className="tippy-see">
                                    Xem tất cả thông báo
                                </a>
                            </div>
                        }
                    >
                        <div className="notification">
                            <FontAwesomeIcon icon={faBell} />
                        </div>
                    </Tooltip>
                    <div className="header-logout" title="Đăng xuất" onClick={handleLogout}>
                        <FontAwesomeIcon icon={faRightToBracket} />
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;
