import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestAuthGetGameByID, requestAuthUpdateGame } from '~/services/game';

const { games, login } = config.routes;

function UpdateGame() {
    const [code, setCode] = useState('');
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [priority, setPriority] = useState('');
    const [coverUrl, setCoverUrl] = useState('');
    const [loginType, setLoginType] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');
    const [tutorialUrl, setTutorialUrl] = useState('');
    const [description, setDescription] = useState('');

    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Thông tin chi tiết trò chơi - Quản trị website';

        if (id) {
            const fetch = async () => {
                dispatch(startLoading());
                const result = await requestAuthGetGameByID(id);

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(login);
                } else if (result.status === 200) {
                    const { title, code, category, avatar_url, cover_url, description, login_type, priority, tutorial_url } = result.data;

                    setCode(code);
                    setTitle(title);
                    setPriority(priority);
                    setCategory(category);
                    setCoverUrl(cover_url);
                    setAvatarUrl(avatar_url);
                    setLoginType(login_type);
                    setDescription(description);
                    setTutorialUrl(tutorial_url);
                } else {
                    navigate(games);
                    alertError(result.error);
                }
            };
            fetch();
        } else {
            navigate(games);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleUpdateTelco = async () => {
        if (!id) {
            return alertError('ID trò chơi không tồn tại');
        }
        if (!title) {
            return alertError('Tên trò chơi không được để trống');
        }
        if (!code) {
            return alertError('Mã trò chơi không được để trống');
        }
        if (!loginType) {
            return alertError('Loại đăng nhập trò chơi không được để trống');
        }
        if (!category) {
            return alertError('Loại trò chơi không được để trống');
        }
        if (!priority) {
            return alertError('Sắp xếp trò chơi không được để trống');
        }
        if (!avatarUrl) {
            return alertError('Ảnh đại diện trò chơi không được để trống');
        }
        if (!tutorialUrl) {
            return alertError('Ảnh hướng dẫn không được để trống');
        }

        const data = {
            title,
            code,
            login_type: loginType,
            category,
            priority,
            avatar_url: avatarUrl,
            cover_url: coverUrl,
            tutorial_url: tutorialUrl,
            description,
        };

        dispatch(startLoading());
        const result = await requestAuthUpdateGame(id, 'info', data);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Thông tin chi tiết" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Thông tin chi tiết</Card.Title>

                                <Link to={games}>
                                    <Button variant="warning">
                                        <FontAwesomeIcon icon={faHouseChimney} />
                                        <span>Trang chính</span>
                                    </Button>
                                </Link>
                            </Card.Header>

                            <Card.Body>
                                <Col xl={6} className="p-0 mt-3">
                                    <Row>
                                        <div className="form-group col-md-12 mb-4">
                                            <label>Tên:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 mb-4">
                                            <label>Mã:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled
                                                value={code}
                                                onChange={(e) => setCode(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 mb-4">
                                            <label>Login:</label>
                                            <select
                                                className="form-control"
                                                value={loginType}
                                                onChange={(e) => setLoginType(e.target.value)}
                                            >
                                                <option value="role">ROLE</option>
                                                <option value="quick">QUICK</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-6 mb-4">
                                            <label>Loại:</label>
                                            <select className="form-control" value={category} onChange={(e) => setCategory(e.target.value)}>
                                                <option value="pc">PC</option>
                                                <option value="mobile">MOBILE</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-6 mb-4">
                                            <label>Sắp xếp:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={priority}
                                                onChange={(e) => setPriority(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-12 mb-4">
                                            <label className="d-block">Logo:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={avatarUrl}
                                                onChange={(e) => setAvatarUrl(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-12 mb-4">
                                            <label className="d-block">Cover:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={coverUrl}
                                                onChange={(e) => setCoverUrl(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-12 mb-4">
                                            <label className="d-block">Tutorial:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={tutorialUrl}
                                                onChange={(e) => setTutorialUrl(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-12">
                                            <label>Mô tả:</label>
                                            <textarea
                                                className="form-control"
                                                rows={3}
                                                placeholder="Có thể bỏ qua nếu không có"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={handleUpdateTelco}>Cập nhật</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default UpdateGame;
