import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import config from '~/configs';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestForgotPassword, requestVerifyEmail } from '~/services/auth';

const { login } = config.routes;

function ForgotPassword() {
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [active, setActive] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Quên mật khẩu? - Quản trị website';
    }, []);

    const handleSendCode = async () => {
        if (active && !code) {
            return alertError('Vui lòng nhập mã xác minh');
        }
        if (!active && !email) {
            return alertError('Vui lòng nhập email tài khoản');
        }

        dispatch(startLoading());
        let result;
        if (active) {
            result = await requestVerifyEmail({ email, code });
        } else {
            result = await requestForgotPassword({ email });
        }

        dispatch(stopLoading());

        if (!active && result.status === 200) {
            alertSuccess(result.message);
            setActive(true);
        }
        if (active && result.status === 200) {
            navigate(login);
            alertSuccess(result.message);
        }
        if (result.error) {
            alertError(result.error);
        }
    };

    return (
        <div className="login">
            <div className="login-content">
                <h3 className="mb-4">{active ? 'Vui lòng nhập mã xác minh' : 'Vui lòng nhập địa chỉ email'}</h3>

                {!active ? (
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                ) : (
                    <Form.Group className="mb-3">
                        <Form.Control type="email" placeholder="Enter your code" value={code} onChange={(e) => setCode(e.target.value)} />
                    </Form.Group>
                )}

                <Form.Group className="mb-3">
                    <Button size="sm" className="btn-block" onClick={handleSendCode}>
                        {active ? 'Xác minh' : 'Gửi mã'}
                    </Button>
                </Form.Group>
            </div>
        </div>
    );
}

export default ForgotPassword;
