import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Card, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faHouseChimney, faPlus } from '@fortawesome/free-solid-svg-icons';

import routes from '~/configs/routes';
import { convertCurrency } from '~/configs';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import ModalDestroy from '~/components/ModalDestroy';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestAuthDestroyPackage, requestAuthGetPackagesByGameID, requestAuthUpdatePackage } from '~/services/game';

function PackageGame() {
    const [game, setGame] = useState('');
    const [packages, setPackages] = useState([]);
    const [destroy, setDestroy] = useState(null);
    const [openDestroy, setOpenDestroy] = useState(false);

    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            const fetch = async () => {
                dispatch(startLoading());
                const result = await requestAuthGetPackagesByGameID(id, null);

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(routes.login);
                } else if (result.status === 200) {
                    setGame(result.game);
                    setPackages(result.data);

                    document.title = `Danh sách gói quà ${result.game} - Quản trị website`;
                } else {
                    navigate(routes.games);
                    alertError(result.error);
                }
            };
            fetch();
        } else {
            navigate(routes.games);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Hàm thay đổi trạng thái
    const handleTogglePackage = async (id, type) => {
        if (!id) {
            alertError('ID gói quà không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestAuthUpdatePackage(id, type, {});

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                const clonePackages = [...packages];

                const packageIndex = clonePackages.findIndex((pack) => pack._id === id);
                if (packageIndex === -1) {
                    return alertError('Gói quà không tồn tại trong danh sách');
                }

                if (type === 'status') {
                    clonePackages[packageIndex].status = result.data;
                }

                setPackages(clonePackages);
                alertSuccess(result.message);
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm xác nhận xóa
    const handleConfirmDestroy = async () => {
        if (!destroy._id) {
            return alertError('ID gói quà cần xoá không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestAuthDestroyPackage(destroy._id);

        dispatch(stopLoading());
        setDestroy(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            const clonePackages = [...packages];

            const packageIndex = clonePackages.findIndex((pack) => pack._id === destroy._id);
            if (packageIndex === -1) {
                return alertError('Gói quà không tồn tại trong danh sách');
            }

            clonePackages.splice(packageIndex, 1);
            setPackages(clonePackages);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name={`Gói quà ${game}`} />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Gói quà {game}</Card.Title>
                                <div>
                                    <Link to={routes.games}>
                                        <Button variant="warning">
                                            <FontAwesomeIcon icon={faHouseChimney} />
                                            <span>Trang chính</span>
                                        </Button>
                                    </Link>
                                    <Link to={routes.games + routes.package + '/create/' + id} className="ml-2">
                                        <Button variant="success">
                                            <FontAwesomeIcon icon={faPlus} />
                                            <span>Thêm mới</span>
                                        </Button>
                                    </Link>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Tên gói</th>
                                                <th>Trò chơi</th>
                                                <th>Mệnh giá</th>
                                                <th>Trạng thái</th>
                                                <th>Ảnh</th>
                                                <th>Gói</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {packages.length > 0 ? (
                                                packages.map((pack) => (
                                                    <tr key={pack._id}>
                                                        <td>{pack.priority}</td>
                                                        <td>{pack.title}</td>
                                                        <td>
                                                            <Link to={`${routes.games}?game_id=${pack.game._id}`} target="_blank">
                                                                <span>{pack.game.code}</span>
                                                                <br />
                                                                <span>{pack.game.title}</span>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`${routes.amount}?amount_id=${pack.amount._id}`} target="_blank">
                                                                <span>{convertCurrency(pack.amount.value)}</span>
                                                                <br />
                                                                <span>{pack.amount.title}</span>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${pack.status ? 'on' : 'off'}`}
                                                                onClick={() => handleTogglePackage(pack._id, 'status')}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <img src={pack.image_url} alt={pack.name} style={{ width: 100, height: 70 }} />
                                                        </td>
                                                        <td>{pack.tag}</td>
                                                        <td>
                                                            <span>{moment(pack.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Xoá"
                                                                onClick={() => {
                                                                    setDestroy(pack);
                                                                    setOpenDestroy(true);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={5}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {openDestroy && destroy && (
                <ModalDestroy show={openDestroy} setShow={setOpenDestroy} name={`${destroy.title}`} onClick={handleConfirmDestroy} />
            )}
        </div>
    );
}

export default PackageGame;
