import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import routes from '~/configs/routes';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestAuthGetGames, requestAuthUpdateGame } from '~/services/game';

function Game() {
    const [games, setGames] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách trò chơi - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestAuthGetGames();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                setGames(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Hàm thay đổi trạng thái
    const handleToggleGame = async (id, type) => {
        if (!id) {
            alertError('ID trò chơi không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestAuthUpdateGame(id, type, {});

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                const cloneGames = [...games];

                const indexGame = cloneGames.findIndex((game) => game._id === id);
                if (indexGame === -1) {
                    return alertError('Trò chơi không tồn tại trong danh sách');
                }

                if (type === 'status') {
                    cloneGames[indexGame].status = result.data;
                }
                if (type === 'active') {
                    cloneGames[indexGame].active = result.data;
                }

                setGames(cloneGames);
                alertSuccess(result.message);
            } else {
                alertError(result.error);
            }
        }
    };

    const activeGames = games.filter((game) => game.active);
    const activatedGames = games.filter((game) => !game.active);

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Trò chơi" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Trò chơi đã cài đặt</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Tên game</th>
                                                <th>Mã</th>
                                                <th>Login</th>
                                                <th>Logo</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activeGames.length > 0 ? (
                                                activeGames.map((game) => (
                                                    <tr key={game._id}>
                                                        <td>{game.priority}</td>
                                                        <td>{game.title}</td>
                                                        <td>{game.code}</td>
                                                        <td>{game.login_type}</td>
                                                        <td>
                                                            <img
                                                                src={game.avatar_url}
                                                                alt={game.title}
                                                                style={{ width: 70, height: 70, borderRadius: 14 }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${game.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggleGame(game._id, 'status')}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span>{moment(game.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        </td>
                                                        <td>
                                                            <Link to={`${routes.games}/edit/${game._id}`}>
                                                                <Button size="sm" className="mr-2" variant="info" title="Chi tiết">
                                                                    <FontAwesomeIcon icon={faPen} />
                                                                </Button>
                                                            </Link>
                                                            <Link to={`${routes.games + routes.package}/${game._id}`}>
                                                                <Button size="sm" className="mr-2" variant="success" title="Gói quà">
                                                                    <FontAwesomeIcon icon={faCubes} />
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Gỡ cài đặt"
                                                                onClick={() => handleToggleGame(game._id, 'active')}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="mt-5">
                            <Card.Header>
                                <Card.Title>Trò chơi chưa cài đặt</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tên game</th>
                                                <th>Mã</th>
                                                <th>Login</th>
                                                <th>Logo</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activatedGames.length > 0 ? (
                                                activatedGames.map((game) => (
                                                    <tr key={game._id}>
                                                        <td>{game.title}</td>
                                                        <td>{game.code}</td>
                                                        <td>{game.login_type}</td>
                                                        <td>
                                                            <img
                                                                src={game.avatar_url}
                                                                alt={game.title}
                                                                style={{ width: 70, height: 70, borderRadius: 14 }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <span>{moment(game.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="success"
                                                                onClick={() => handleToggleGame(game._id, 'active')}
                                                            >
                                                                Cài đặt
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Game;
