import routes from '~/configs/routes';

import Home from '~/layouts/Home';

import Destroy from '~/pages/Destroy';

import Setting from '~/pages/Setting';

import NotFound from '~/layouts/NotFound';

import Users from '~/pages/account/Users';
import Admin from '~/pages/account/Admin';

import Advertise from '~/pages/Advertise';
import Request from '~/pages/Advertise/Request';

import Actions from '~/pages/history/Actions';
import HistoryUser from '~/pages/history/User';
import HistoryAdmin from '~/pages/history/Admin';
import HistoryCallBack from '~/pages/history/Callback';

import Partners from '~/pages/module/Partners';
import UpdatePartner from '~/pages/module/Partners/Update';

import Login from '~/layouts/components/Login';
import ForgotPassword from '~/components/ForgotPassword';

import Notification from '~/pages/Notification';

import StatUser from '~/pages/statistic/Users';
import StatDaily from '~/pages/statistic/Daily';
import Callback from '~/pages/statistic/Callback';
import StatCharging from '~/pages/statistic/Charging';

import Game from '~/pages/Game';
import UpdateGame from '~/pages/Game/UpdateGame';
import PackageGame from '~/pages/Game/PackageGame';
import CreatePackage from '~/pages/Game/CreatePackage';

import Telco from '~/pages/softcard/Telco';
import Amount from '~/pages/softcard/Amount';

const privateRoutes = [
    { path: routes.home, component: Home },
    { path: routes.games, component: Game },
    { path: routes.users, component: Users },
    { path: routes.admin, component: Admin },
    { path: routes.tools, component: Destroy },
    { path: routes.amount, component: Amount },
    { path: routes.softcard, component: Telco },
    { path: routes.request, component: Request },
    { path: routes.settings, component: Setting },
    { path: routes.partners, component: Partners },
    { path: '*', component: NotFound, layout: null },
    { path: routes.advertises, component: Advertise },
    { path: routes.login, component: Login, layout: null },
    { path: routes.notifications, component: Notification },
    { path: routes.games + '/edit/:id', component: UpdateGame },
    { path: routes.partners + '/edit', component: UpdatePartner },
    { path: routes.history + routes.actions, component: Actions },
    { path: routes.statistic + routes.users, component: StatUser },
    { path: routes.history + routes.users, component: HistoryUser },
    { path: routes.history + routes.admin, component: HistoryAdmin },
    { path: routes.forgot, component: ForgotPassword, layout: null },
    { path: routes.statistic + routes.dailystat, component: StatDaily },
    { path: routes.statistic + routes.chargings, component: StatCharging },
    { path: routes.history + routes.callback, component: HistoryCallBack },
    { path: routes.games + routes.package + '/:id', component: PackageGame },
    { path: routes.games + routes.package + '/create/:id', component: CreatePackage },
    { path: routes.statistic + routes.chargings + routes.callback, component: Callback },
];

export { privateRoutes };
