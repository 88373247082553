import 'moment/locale/vi';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import config from '~/configs';
import { alertError } from '~/configs/alert';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import CusPagination from '~/components/CusPagination';
import { requestGetHistories } from '~/services/history';
import { startLoading, stopLoading } from '~/redux/reducer/module';

const { login } = config.routes;

function Callback() {
    const [pages, setPages] = useState([]);
    const [histories, setHistories] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const page = searchParams.get('page');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Lịch sử callback -  Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetHistories('callback', page || 1);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setHistories(result.data);
                setPages(result.pages);
            } else {
                alertError(result.error);
            }
        };
        fetch();
    }, [page, dispatch, navigate]);

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Lịch sử callback" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Mô tả</th>
                                                <th>Ngày tạo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {histories.length > 0 ? (
                                                histories.map((callback) => (
                                                    <tr key={callback._id}>
                                                        <td>{callback.description}</td>
                                                        <td>{moment(callback.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={6}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                            {pages > 1 && (
                                <Card.Footer>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="float-right">
                                                <CusPagination page={page} pages={pages} setSearchParams={setSearchParams} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Callback;
