import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';

import config, { convertCurrency } from '~/configs';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestDestroyCharging, requestGetStatChargings, requestUpdateCharging } from '~/services/charging';

const { login } = config.routes;

function ModalChar({ show, setShow, chargingId, callBack }) {
    const [stat, setStat] = useState(null);
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (chargingId) {
            const fetch = async () => {
                dispatch(startLoading());
                const result = await requestGetStatChargings({ type: 'detail', id: chargingId });

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(login);
                } else if (result.status === 200) {
                    setStat(result.data);
                    setDescription(result.data.description);
                } else {
                    alertError(result.error);
                }
            };
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chargingId]);

    // Xóa thẻ
    const handleDestroy = async () => {
        if (!chargingId) {
            return alertError('Thẻ cào này không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestDestroyCharging(null, chargingId);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            setShow(false);
            alertSuccess(result.message);
            callBack('delete', chargingId);
        } else {
            alertError(result.error);
        }
    };

    // Cập nhật thẻ
    const handleUpdate = async (type) => {
        dispatch(startLoading());
        if (type === 'wrong') {
            if (!amount) {
                return alertError('Vui lòng chọn mệnh giá đúng');
            }

            const result = await requestUpdateCharging('wrong', chargingId, { amount });

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setShow(false);
                alertSuccess(result.message);
                callBack('wrong', result.data);
            } else {
                alertError(result.error);
            }
        } else if (type === 'fault') {
            const result = await requestUpdateCharging('fault', chargingId, {});

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setShow(false);
                alertSuccess(result.message);
                callBack('wrong', result.data);
            } else {
                alertError(result.error);
            }
        } else if (type === 'success') {
            const result = await requestUpdateCharging('success', chargingId, {});

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setShow(false);
                alertSuccess(result.message);
                callBack('wrong', result.data);
            } else {
                alertError(result.error);
            }
        } else if (type === 'ip') {
            const result = await requestUpdateCharging('ip', chargingId, {});

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setShow(false);
                alertSuccess(result.message);
            } else {
                alertError(result.error);
            }
        } else {
            alertError('Hành động đang bị chặn thử lại sau');
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-90w" backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Yêu cầu: {stat?.request_id}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="my-4">
                <div className="table-responsive">
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Mạng</th>
                                <th>Thông tin thẻ</th>
                                <th>Trạng thái</th>
                                <th>Khai/Thực</th>
                                <th>Phí/Phạt</th>
                                <th>Tiền nhận</th>
                                <th>IP khách</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stat ? (
                                <tr>
                                    <td>{stat.telco}</td>
                                    <td>
                                        <b>M: {stat.code}</b>
                                        <br />
                                        <span>S: {stat.serial}</span>
                                    </td>
                                    <td>
                                        <Badge
                                            bg={
                                                stat.status === 1
                                                    ? 'success'
                                                    : stat.status === 2
                                                    ? 'info'
                                                    : stat.status === 99 ||
                                                      stat.status === 101 ||
                                                      stat.status === 103 ||
                                                      stat.status === 104
                                                    ? 'warning'
                                                    : 'danger'
                                            }
                                        >
                                            {stat.message}
                                        </Badge>
                                    </td>
                                    <td>
                                        <b>{convertCurrency(stat.declared_value)}</b>
                                        <br />
                                        <span className="text-success">{convertCurrency(stat.value)}</span>
                                    </td>
                                    <td>{stat.fees}%</td>
                                    <td className="text-info">{convertCurrency(stat.amount)}</td>
                                    <td>
                                        <span>{stat.ip}</span>
                                        <br />
                                        <span>{stat.request?.ip}</span>
                                        <br />
                                        <span>{stat.request?.address}</span>
                                    </td>
                                    <td>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            className="mr-2"
                                            title="Xóa thẻ sẽ không thể khôi phục lại"
                                            onClick={handleDestroy}
                                        >
                                            Xóa thẻ
                                        </Button>
                                        <Button
                                            size="sm"
                                            title={
                                                stat.ip_block
                                                    ? 'Bỏ chặn IP khách hàng sẽ được phép gửi thẻ'
                                                    : 'Chặn IP khách hàng không được phép gửi thẻ'
                                            }
                                            onClick={() => handleUpdate('ip')}
                                        >
                                            {stat.ip_block ? 'Bỏ chặn IP' : 'Chặn IP'}
                                        </Button>
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan={8}>Không có dữ liệu</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Form.Group className="mt-4 mt-md-5">
                        <Form.Label>
                            <b>Ghi chú:</b>
                        </Form.Label>
                        <textarea className="form-control" disabled value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-md-start">
                <Row className="col-12 justify-content-between">
                    <Col md={2} className="col-8 px-0 mr-md-3">
                        <select className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)}>
                            <option value="">Chọn mệnh giá đúng</option>
                            <option value="20000">20.000đ</option>
                            <option value="50000">50.000đ</option>
                            <option value="100000">100.000đ</option>
                            <option value="200000">200.000đ</option>
                            <option value="300000">300.000đ</option>
                            <option value="500000">500.000đ</option>
                            <option value="1000000">1.000.000đ</option>
                        </select>
                    </Col>
                    <Button className="mr-md-2 mr-0 ml-2 ml-md-0" onClick={() => handleUpdate('wrong')}>
                        Sai mệnh giá
                    </Button>
                    <Button className="mr-2 mt-2 mt-md-0" variant="danger" onClick={() => handleUpdate('fault')}>
                        Thẻ lỗi
                    </Button>
                    <Button variant="success" className="mt-md-0 mt-2" onClick={() => handleUpdate('success')}>
                        Thẻ đúng
                    </Button>
                    <Col className="mt-2 mt-md-0 col-0 pr-0">
                        <Button variant="secondary" onClick={() => setShow(false)} className="float-right">
                            Đóng
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalChar;
