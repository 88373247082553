import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Card, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import routes from '~/configs/routes';
import ModalAmount from '../ModalAmount';
import { convertCurrency } from '~/configs';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestAuthGetAmounts, requestAuthUpdateAmount } from '~/services/amount';

function Amount() {
    const [amount, setAmount] = useState(null);
    const [amounts, setAmounts] = useState([]);
    const [openUpdate, setOpenUpdate] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách mệnh giá - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestAuthGetAmounts();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                setAmounts(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Hàm thay đổi trạng thái
    const handleToggleAmount = async (id, type) => {
        if (!id) {
            alertError('ID mệnh giá không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestAuthUpdateAmount(id, type, {});

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                const cloneAmounts = [...amounts];

                const indexAmount = cloneAmounts.findIndex((amount) => amount._id === id);
                if (indexAmount === -1) {
                    return alertError('Mệnh giá không tồn tại trong danh sách');
                }

                if (type === 'status') {
                    cloneAmounts[indexAmount].status = result.data;
                }
                if (type === 'active') {
                    cloneAmounts[indexAmount].active = result.data;
                }
                setAmounts(cloneAmounts);
                alertSuccess(result.message);
            } else {
                alertError(result.error);
            }
        }
    };

    const activeAmounts = amounts.filter((amount) => amount.active);
    const activatedAmounts = amounts.filter((amount) => !amount.active);

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Danh sách mệnh giá" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Mệnh giá đã cài đặt</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Tên</th>
                                                <th>Giá trị</th>
                                                <th>Giảm giá</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activeAmounts.length > 0 ? (
                                                activeAmounts.map((amount) => (
                                                    <tr key={amount._id}>
                                                        <td>{amount.priority}</td>
                                                        <td>{amount.title}</td>
                                                        <td>{convertCurrency(amount.value)}</td>
                                                        <td>{amount.discount}%</td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${amount.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggleAmount(amount._id, 'status')}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span>{moment(amount.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                className="mr-2"
                                                                variant="info"
                                                                title="Sửa"
                                                                onClick={() => {
                                                                    setAmount(amount);
                                                                    setOpenUpdate(true);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faPen} />
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Gỡ cài đặt"
                                                                onClick={() => handleToggleAmount(amount._id, 'active')}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="mt-5">
                            <Card.Header>
                                <Card.Title>Mệnh giá chưa cài đặt</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tên</th>
                                                <th>Giá trị</th>
                                                <th>Giảm giá</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activatedAmounts.length > 0 ? (
                                                activatedAmounts.map((amount) => (
                                                    <tr key={amount._id}>
                                                        <td>{amount.title}</td>
                                                        <td>{convertCurrency(amount.value)}</td>
                                                        <td>{amount.discount}%</td>
                                                        <td>
                                                            <span>{moment(amount.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="success"
                                                                onClick={() => handleToggleAmount(amount._id, 'active')}
                                                            >
                                                                Cài đặt
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {openUpdate && amount && (
                <ModalAmount show={openUpdate} setShow={setOpenUpdate} amount={amount} callback={amounts} setCallback={setAmounts} />
            )}
        </div>
    );
}

export default Amount;
