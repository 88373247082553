import routes from './routes';
import {
    faGauge,
    faUsers,
    faCubes,
    faGears,
    faWrench,
    faHeadset,
    faGamepad,
    faCreditCard,
    faChartColumn,
    faClockRotateLeft,
} from '@fortawesome/free-solid-svg-icons';

const {
    home,
    admin,
    games,
    users,
    tools,
    amount,
    history,
    actions,
    request,
    partners,
    callback,
    settings,
    softcard,
    statistic,
    chargings,
    dailystat,
    advertises,
} = routes;

const sidebar = [
    {
        title: 'Trang chủ',
        path: home,
        icon: faGauge,
        sub: null,
    },
    {
        title: 'Thanh toán',
        path: null,
        icon: faCreditCard,
        sub: [
            {
                title: 'Loại thẻ',
                path: softcard,
            },
            {
                title: 'Mệnh giá',
                path: amount,
            },
        ],
    },
    {
        title: 'Trò chơi',
        path: null,
        icon: faGamepad,
        sub: [
            {
                title: 'Danh sách',
                path: games,
            },
        ],
    },
    {
        title: 'Thống kê',
        path: null,
        icon: faChartColumn,
        sub: [
            {
                title: 'Thống kê đổi thẻ',
                path: statistic + chargings,
            },
            {
                title: 'Tổng hợp ngày',
                path: statistic + dailystat,
            },
            {
                title: 'Sản lượng thành viên',
                path: statistic + users,
            },
        ],
    },
    {
        title: 'Tài khoản',
        path: null,
        icon: faUsers,
        sub: [
            {
                title: 'Quản trị viên',
                path: admin,
            },
            {
                title: 'Người dùng',
                path: users,
            },
        ],
    },
    {
        title: 'Lịch sử',
        path: null,
        icon: faClockRotateLeft,
        sub: [
            {
                title: 'Quản trị viên',
                path: history + admin,
            },
            {
                title: 'Người dùng',
                path: history + users,
            },
            {
                title: 'Lịch sử callback',
                path: history + callback,
            },
            {
                title: 'Hoạt động quản trị',
                path: history + actions,
            },
        ],
    },
    {
        title: 'Công cụ',
        path: null,
        icon: faWrench,
        sub: [
            {
                title: 'Xóa dữ liệu',
                path: tools,
            },
        ],
    },
    {
        title: 'Quảng cáo',
        path: null,
        icon: faHeadset,
        sub: [
            {
                title: 'Lượt truy cập',
                path: request,
            },
            {
                title: 'Danh sách',
                path: advertises,
            },
        ],
    },
    {
        title: 'Mô-đun khác',
        path: null,
        icon: faCubes,
        sub: [
            {
                title: 'Đối tác kết nối',
                path: partners,
            },
        ],
    },
    {
        title: 'Cấu hình hệ thống',
        path: null,
        icon: faGears,
        sub: [
            {
                title: 'Cài đặt',
                path: settings,
            },
        ],
    },
];

export default sidebar;
