import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import routes from '~/configs/routes';
import { logoutSuccess } from '~/redux/reducer/auth';
import { requestAuthUpdateTelco } from '~/services/telco';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';

function ModalTelco({ show, setShow, telco, callback, setCallback }) {
    const [code, setCode] = useState(telco.code);
    const [title, setTitle] = useState(telco.title);
    const [priority, setPriority] = useState(telco.priority);
    const [imageUrl, setImageUrl] = useState(telco.image_url);
    const [codeLength, setCodeLength] = useState(telco.code_length);
    const [description, setDescription] = useState(telco.description);
    const [serialLength, setSerialLength] = useState(telco.serial_length);
    const [serialFormat, setSerialFormat] = useState(telco.serial_format.join(', '));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUpdateSoftCard = async () => {
        if (!telco._id) {
            return alertError('ID thẻ không tồn tại');
        }
        if (!title) {
            return alertError('Tên thẻ không được để trống');
        }
        if (!code) {
            return alertError('Mã thẻ không được để trống');
        }
        if (!priority) {
            return alertError('Sắp xếp thẻ không được để trống');
        }
        if (!imageUrl) {
            return alertError('Link ảnh thẻ không được để trống');
        }
        if (!codeLength) {
            return alertError('Độ dài mã thẻ không được để trống');
        }
        if (!serialLength) {
            return alertError('Độ dài serial thẻ không được để trống');
        }

        const serialArray = serialFormat.split(',').map((item) => item.trim());
        if (serialArray.length < 1) {
            return alertError('Tối thiểu có 1 định dạng serial thẻ');
        }

        dispatch(startLoading());
        const data = {
            code,
            title,
            priority,
            image_url: imageUrl,
            description: description,
            serial_format: serialArray,
            code_length: parseInt(codeLength),
            serial_length: parseInt(serialLength),
        };

        const result = await requestAuthUpdateTelco(telco._id, 'info', data);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            const cloneTelcos = [...callback];

            const indexTelco = cloneTelcos.findIndex((item) => item._id === telco._id);
            if (indexTelco === -1) {
                return alertError('Thẻ không tồn tại trong danh sách');
            }

            cloneTelcos[indexTelco] = result.data;
            setCallback(cloneTelcos);
            setShow(false);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false} size="xl">
            <Modal.Header>
                <Modal.Title style={{ fontSize: '2rem' }}>Sửa thẻ</Modal.Title>
            </Modal.Header>
            <Modal.Body className="my-4">
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tên:</Form.Label>
                            <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Mã:</Form.Label>
                            <Form.Control value={code} onChange={(e) => setCode(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Sắp xếp:</Form.Label>
                            <Form.Control value={priority} onChange={(e) => setPriority(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Link ảnh:</Form.Label>
                            <Form.Control value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Định dạng serial:</Form.Label>
                            <Form.Control
                                placeholder="Cách nhau bởi dấu phẩy (,)"
                                value={serialFormat}
                                onChange={(e) => setSerialFormat(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Độ dài mã thẻ:</Form.Label>
                            <Form.Control value={codeLength} onChange={(e) => setCodeLength(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Độ dài serial:</Form.Label>
                            <Form.Control value={serialLength} onChange={(e) => setSerialLength(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Mô tả:</Form.Label>
                            <Form.Control
                                placeholder="Có thể bỏ qua"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Đóng
                </Button>
                <Button variant="success" onClick={handleUpdateSoftCard}>
                    Cập nhật
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalTelco;
