import request from '~/utils';

export const requestAuthGetGames = async (id = null, type = null) => {
    try {
        const res = await request.get('/games', {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestAuthGetGameByID = async (id) => {
    try {
        const res = await request.get(`/games/${id}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestAuthUpdateGame = async (id, type, data) => {
    try {
        const res = await request.put('/games/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestAuthGetPackagesByGameID = async (id, type = null) => {
    try {
        const res = await request.get(`/packages/${id}`, {
            params: {
                type,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestAuthUpdatePackage = async (id, type, data) => {
    try {
        const res = await request.put('/packages/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestAuthDestroyPackage = async (id) => {
    try {
        const res = await request.delete(`/packages/destroy/${id}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestAuthCreatePackage = async (data) => {
    try {
        const res = await request.post('/packages/create', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
