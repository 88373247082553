import request from '~/utils';

export const requestAuthGetAmounts = async () => {
    try {
        const res = await request.get('/amounts');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestAuthUpdateAmount = async (id, type, data) => {
    try {
        const res = await request.put('/amounts/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
