import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import routes from '~/configs/routes';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { requestAuthUpdateAmount } from '~/services/amount';
import { startLoading, stopLoading } from '~/redux/reducer/module';

function ModalAmount({ show, setShow, amount, callback, setCallback }) {
    const [title, setTitle] = useState(amount.title);
    const [value, setValue] = useState(amount.value);
    const [discount, setDiscount] = useState(amount.discount);
    const [priority, setPriority] = useState(amount.priority);
    const [description, setDescription] = useState(amount.description);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUpdateSoftCard = async () => {
        if (!amount._id) {
            return alertError('ID mệnh giá không tồn tại');
        }
        if (!title) {
            return alertError('Tên mệnh giá không được để trống');
        }
        if (!value) {
            return alertError('Mã mệnh giá không được để trống');
        }
        if (!priority) {
            return alertError('Sắp xếp mệnh giá không được để trống');
        }

        dispatch(startLoading());
        const data = {
            title,
            value,
            discount,
            priority,
            description,
        };

        const result = await requestAuthUpdateAmount(amount._id, 'info', data);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            const cloneAmounts = [...callback];

            const indexAmount = cloneAmounts.findIndex((item) => item._id === amount._id);
            if (indexAmount === -1) {
                return alertError('Mệnh giá không tồn tại trong danh sách');
            }

            cloneAmounts[indexAmount] = result.data;
            setCallback(cloneAmounts);
            setShow(false);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false} size="xl">
            <Modal.Header>
                <Modal.Title style={{ fontSize: '2rem' }}>Sửa mệnh giá</Modal.Title>
            </Modal.Header>
            <Modal.Body className="my-4">
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tên:</Form.Label>
                            <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Giá trị:</Form.Label>
                            <Form.Control value={value} onChange={(e) => setValue(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Giảm giá (%):</Form.Label>
                            <Form.Control value={discount} onChange={(e) => setDiscount(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Sắp xếp:</Form.Label>
                            <Form.Control value={priority} onChange={(e) => setPriority(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Mô tả:</Form.Label>
                            <Form.Control
                                placeholder="Có thể bỏ qua"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Đóng
                </Button>
                <Button variant="success" onClick={handleUpdateSoftCard}>
                    Cập nhật
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalAmount;
